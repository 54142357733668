/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />
/// <reference path='../../../../../node_modules/@types/jquery.validation/index.d.ts' />

class FormValidation {
    public static rebuildRules(form: JQuery) {
        form.removeData('validator')
            .removeData("unobtrusiveValidation");
        (<any>$.validator).unobtrusive.parse(form);
        addClasses();
    }

    public static validateForm(form: JQuery): boolean {
        // Trigger validation on form
        if (!form.valid()) {
            // If form is not valid, add style classes to validation summary and focus invalid elements
            addClasses();
            form.validate().focusInvalid();
            return false;
        }

        return true;
    }
}

function addClasses()
{
    // Validation Summary (borrowed from: http://stackoverflow.com/a/14651140/316989)
    $("span.field-validation-valid, span.field-validation-error").addClass('help-block');
    $("div.form-group").has("span.field-validation-error").addClass('has-error');
    $("div.validation-summary-errors").has("li:visible").addClass("alert alert-block alert-danger");
}

$(() => {
    addClasses();

    // any validation summary items should be encapsulated by a class alert and alert-danger
    $('.validation-summary-errors').each(function () {
        $(this).addClass('alert');
        $(this).addClass('alert-danger');
    });

    // update validation fields on submission of form
    if ($.fn.validate !== undefined) {
        $('form').submit(function () {
            if ($(this).validate().valid())
                if ($(this).valid()) {
                    $(this).find('div.control-group').each(function () {
                        if ($(this).find('span.field-validation-error').length === 0) {
                            $(this).removeClass('has-error');
                            $(this).addClass('has-success');
                        }
                    });
                }
                else {
                    $(this).find('div.control-group').each(function () {
                        if ($(this).find('span.field-validation-error').length > 0) {
                            $(this).removeClass('has-success');
                            $(this).addClass('has-error');
                        }
                    });
                    $('.validation-summary-errors').each(function () {
                        if ($(this).hasClass('alert-danger') === false) {
                            $(this).addClass('alert');
                            $(this).addClass('alert-danger');
                        }
                    });
                }
            $('.validation-summary-errors').each(function () {
                $(this).addClass('alert');
                $(this).addClass('alert-danger');
            });
            $('.validation-summary-valid').each(function () {
                $(this).removeClass('alert');
                $(this).removeClass('alert-danger');
            });
        });
    }

    // check each form-group for errors on ready
    $('form').each(function () {
        $(this).find('div.form-group').each(function () {
            if ($(this).find('span.field-validation-error').length > 0) {
                $(this).addClass('has-error');
            }
        });
    });
});

// FORM VALIDATION CLASSES
// Borrowed from: http://stackoverflow.com/a/19842032/316989
if ($.validator !== undefined) {
    // Cast to any to work around outdated jquery.validate typing.
    // TODO: When DefinitelyTyped supports JQuery Validate v1.17, we can remove this.
    (<any>$.validator).setDefaults({
        highlight: element => {
            $(element).closest(".form-group").addClass("has-error");
        },
        unhighlight: element => {
            $(element).closest(".form-group").removeClass("has-error");
        },
        normalizer: function (value) {
            // JQuery Validate 1.14.0 changed the required rule to not trim spaces, so just a space would now be considered valid.
            // We want to preserve the old behavior, so we'll trim values before validation.
            // Additionally, IE and the email validation rules don't play nicely since, unlike other browsers,
            // IE doesn't trim spaces from email inputs
            // Note that the normalization only happens for validation, it doesn't change the actual value in the form.
            // We treat passwords as an edge case; we don't want to normalize passwords.
            var isPassword = this.type === 'password';
            return isPassword ? value : $.trim(value);
        }
    });
}
